import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import { IconLanguage } from '../../atoms/icons';
import Logo from '../../atoms/logo/logo';
import { Text } from '../../atoms/text/text';
import DonateButton from '../../molecules/donate-button/donate-button';
import UserMainNav from '../../molecules/user-main-nav/user-main-nav';
import { Link } from '@components/atoms/link/link';
import { useCurrentUser } from '@hooks/use-user';
import LanguageMenuItems from '@components/molecules/language-menu-items/language-menu-items';
interface Props {
  onMobileNavToggle?: (param: boolean) => void;
}

const messages = defineMessages({
  labelLanguage: {
    id: '8HCs5M',
    defaultMessage: 'select language for site',
    description: 'Label to select language for the site',
  },
  navLabel: {
    id: 'gLlRmL',
    defaultMessage: 'site navigation',
    description: 'Screen reader label for site navigation.',
  },
});

const Header = (props: Props) => {
  const router = useRouter();
  const { data: user } = useCurrentUser();
  const isLoggedIn = user && user.status !== 'guest';
  const intl = useIntl();
  return (
    <div className="container relative my-6 sm:my-12">
      <div className="flex flex-row gap-4 justify-between">
        <div className="w-[200px] min-[450px]:w-[300px]">
          <Link href={isLoggedIn ? '/account' : '/'}>
            <Logo />
            <span className="sr-only">
              {isLoggedIn && (
                <FormattedMessage
                  id="TpqG13"
                  defaultMessage="My Dashboard"
                  description="Label for screen readers on main header link (when logged in)."
                />
              )}
              {!isLoggedIn && (
                <FormattedMessage
                  id="CE5Nf9"
                  defaultMessage="AdventistGiving"
                  description="Label for screen readers on main header link."
                />
              )}
            </span>
          </Link>
        </div>
        <nav
          aria-label={intl.formatMessage(messages.navLabel)}
          className="flex flex-row items-center hidden lg:flex justify-between space-x-6"
        >
          <Link href="/donate" className="tracking-wide">
            <DonateButton data-cy="header-nav" />
          </Link>
          {isLoggedIn && <UserMainNav />}
          {!isLoggedIn && (
            <Link
              href="/login"
              className="flex space-x-1 p-2 tracking-wide uppercase"
              data-cy="header-nav"
            >
              <span className="text-xs font-sans text-nad-alps-night hover:text-nad-ming font-bold">
                <FormattedMessage
                  id="TMGQ/Y"
                  defaultMessage="Sign In"
                  description="Sign in link in header navigation"
                />
              </span>
            </Link>
          )}

          <div className="flex flex-col text-center">
            <Menu as="div" className="">
              {({ open }) => (
                <>
                  <Menu.Button
                    className="flex flex-row w-full space-x-1 p-2 items-center rounded-md group"
                    data-cy="header-nav"
                    aria-label={intl.formatMessage(messages.labelLanguage)}
                  >
                    <div className="mt-0.5">
                      <IconLanguage
                        color={open ? 'nad-ming' : 'nad-alps-night'}
                        className="group-hover:fill-nad-ming"
                      />
                    </div>
                    <Text
                      size="XS"
                      color={open ? 'nad-ming' : 'nad-alps-night'}
                      className="uppercase font-sans font-bold group-hover:text-nad-ming"
                    >
                      {router.locale || 'en'}
                    </Text>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-20 right-0 mt-2 py-1.5 w-32 bg-white shadow-lg focus:outline-none border-[3px] border-nad-light-blue">
                      <LanguageMenuItems />
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </nav>
        <button
          className="space-y-[3px] px-2 -mr-2 lg:hidden flex flex-col justify-center cursor-pointer"
          aria-haspopup
          onClick={() => {
            props.onMobileNavToggle && props.onMobileNavToggle(true);
          }}
        >
          <span className="sr-only">
            <FormattedMessage
              id="/Lf9ej"
              defaultMessage="Menu"
              description="Screen reader label for mobile button to open main menu"
            />
          </span>
          <div className="w-4 h-[3px] bg-nad-blue"></div>
          <div className="w-4 h-[3px] bg-nad-blue"></div>
          <div className="w-4 h-[3px] bg-nad-blue"></div>
        </button>
      </div>
    </div>
  );
};

export default Header;
