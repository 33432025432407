import { strokeColors, Props } from '.';

export const IconPencil = ({
  width = 18,
  height = 18,
  color = 'nad-blue',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 18 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1971_48156)">
        <path
          d="M3 15.5002H6L13.875 7.62523C14.2728 7.2274 14.4963 6.68784 14.4963 6.12523C14.4963 5.56262 14.2728 5.02305 13.875 4.62523C13.4772 4.2274 12.9376 4.00391 12.375 4.00391C11.8124 4.00391 11.2728 4.2274 10.875 4.62523L3 12.5002V15.5002Z"
          className={strokeColors[color]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.125 5.375L13.125 8.375"
          className={strokeColors[color]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1971_48156">
          <rect
            width="18"
            height="19"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
