import dayjs from 'dayjs';
import Image from 'next/image';
import { defineMessages, useIntl } from 'react-intl';
import FooterNav from '../../molecules/footer-nav/footer-nav';
import logoWhite from '../../../public/logo-white.svg';

const messages = defineMessages({
  copyright: {
    id: 'luv2Kz',
    defaultMessage:
      'Copyright ©2014-{year}, AdventistGiving. North American Division of the Seventh-day Adventist Church. All rights reserved.',
    description: 'Copyright text on footer.',
  },
  privacyPolicy: {
    id: 'f6e+Qx',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy policy link in footer.',
  },
});

const Footer = () => {
  const intl = useIntl();
  const year = dayjs().format('YYYY');
  return (
    <div className="bg-nad-blue-2 flex flex-grow pt-0 lg:py-16 p-6">
      <div className="container relative">
        <div className="flex flex-col lg:flex-row w-full gap-10">
          <div className="print:hidden w-full mt-12 lg:mt-0 lg:w-7/12 flex flex-col justify-between">
            <div className="lg:w-80">
              <Image src={logoWhite} alt="AdventistGiving" />
            </div>
          </div>
          <FooterNav className="print:hidden lg:w-5/12 w-full" />
          <div className="space-y-4">
            <p className="font-sans text-nad-white text-xs block lg:hidden font-light">
              {intl.formatMessage(messages.copyright, { year, br: <br /> })}
            </p>
            <p className="print:hidden">
              <a
                className="lg:hidden underline text-nad-white font-sans font-light"
                href="https://adventistgiving.helpscoutdocs.com/article/84-privacy-policy"
              >
                {intl.formatMessage(messages.privacyPolicy)}
              </a>
            </p>
          </div>
        </div>
        <p className="font-sans text-nad-white text-xs hidden lg:block font-light mt-24">
          {intl.formatMessage(messages.copyright, { year, br: '' })}
          <a
            className="print:hidden underline ml-5"
            href="https://adventistgiving.helpscoutdocs.com/article/84-privacy-policy"
          >
            {intl.formatMessage(messages.privacyPolicy)}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
