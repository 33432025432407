import { ReactNode } from 'react';

type TypeMessage = 'info' | 'danger' | 'desc';
type textAlign = 'right' | 'center' | 'left';
type VariantStyle = 'normal' | 'narrow';
interface Props {
  children: ReactNode;
  className?: string;
  type?: TypeMessage;
  textAlign?: textAlign;
  variant?: VariantStyle;
  'data-testid'?: string;
}

const Message = ({
  children,
  className = '',
  type = 'info',
  textAlign = 'center',
  variant = 'normal',
  'data-testid': testId,
  ...props
}: Props) => {
  const colorClasses =
    type === 'info' ? 'text-nad-blue bg-nad-light-blue' : 'bg-nad-alps-light';
  const variantClasses: Record<VariantStyle, string> = {
    normal: 'p-5 lg:p-7',
    narrow: 'p-4',
  };
  if (type == 'danger') {
    return (
      <div
        className={`text-${textAlign} text-xs p-4 bg-nad-scarlett-light border-2 border-nad-scarlett ${className}`}
        data-testid={testId || 'message-danger'}
        {...props}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={`text-${textAlign} ${variantClasses[variant]} ${colorClasses} ${className} text-xs`}
      data-testid={testId || 'message-info'}
      {...props}
    >
      {children}
    </div>
  );
};

export default Message;
