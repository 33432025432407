import { ElementType, HtmlHTMLAttributes, ReactNode } from 'react';

export type TextColors =
  | 'nad-white'
  | 'nad-blue'
  | 'nad-alps-earth'
  | 'nad-alps-night'
  | 'nad-alps-night-2'
  | 'nad-blue-2'
  | 'nad-scarlett'
  | 'nad-ming'
  | 'nad-green'
  | 'nad-orange'
  | 'black';

export type TextSizes =
  | '6XL'
  | '4XL'
  | '3XL'
  | '2XL'
  | 'XL'
  | 'L'
  | 'M'
  | 'S'
  | 'XS'
  | '2XS'
  | '3XS';

export interface TextProps extends HtmlHTMLAttributes<HTMLHeadingElement> {
  size: TextSizes;
  as?: ElementType;
  color?: TextColors;
  children?: ReactNode;
}

export const TEXT_COLOR_MAP = {
  'nad-white': 'text-nad-white',
  'nad-blue': 'text-nad-blue',
  'nad-blue-2': 'text-nad-blue-2',
  'nad-alps-earth': 'text-nad-alps-earth',
  'nad-alps-night': 'text-nad-alps-night',
  'nad-alps-night-2': 'text-nad-alps-night-2',
  'nad-scarlett': 'text-nad-scarlett',
  black: 'text-black',
  'nad-ming': 'text-nad-ming',
  'nad-green': 'text-nad-green',
  'nad-orange': 'text-nad-orange',
};

export const Text = ({
  size,
  color,
  children,
  as,
  className = '',
  ...props
}: TextProps) => {
  className = [className, color ? TEXT_COLOR_MAP[color] : ''].join(' ');
  const classMap: Record<TextSizes, string> = {
    '6XL': `${xxxxxxlClasses}`,
    '4XL': `${xxxxlClasses}`,
    '3XL': `${xxxlClasses}`,
    '2XL': `${xxlClasses}`,
    XL: `${xlClasses}`,
    L: `${lClasses}`,
    M: `${mClasses}`,
    S: `${sClasses}`,
    XS: `${xsClasses}`,
    '2XS': `${xxsClasses}`,
    '3XS': `${xxxsClasses}`,
  };
  const classes = classMap[size];
  const Component = as ?? 'p';
  return (
    <Component className={`${classes} ${className}`} {...props}>
      {children}
    </Component>
  );
};

export const xxxxxxlClasses = `text-6xl`;
export const xxxxlClasses = `text-4xl`;
export const xxxlClasses = `text-3xl`;
export const xxlClasses = `text-2xl`;
export const xlClasses = `text-xl`;
export const lClasses = `text-l`;
export const mClasses = `text-m`;
export const sClasses = `text-s`;
export const xsClasses = `text-xs`;
export const xxsClasses = `text-2xs`;
export const xxxsClasses = `text-3xs`;
