import { iconColorOptions, Props, strokeColors } from '.';

export const IconChurch = ({
  width = 45,
  height = 45,
  color = 'nad-alps-night',
  size = 'M',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {size == 'M' && <SizeM color={color} />}
      {size == 'S' && <SizeS color={color} />}
    </svg>
  );
};

const SizeM = (props: { color: iconColorOptions }) => {
  return (
    <>
      <path
        d="M5.625 39.375H39.375"
        className={`${strokeColors[props.color]}`}
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 39.375V31.875C18.75 30.8804 19.1451 29.9266 19.8484 29.2234C20.5516 28.5201 21.5054 28.125 22.5 28.125C23.4946 28.125 24.4484 28.5201 25.1516 29.2234C25.8549 29.9266 26.25 30.8804 26.25 31.875V39.375"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 9.375H26.25"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 5.625V15"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M33.75 26.25V39.375M11.25 39.375V26.25V39.375ZM7.5 30L22.5 15L37.5 30H7.5Z"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

const SizeS = (props: { color: iconColorOptions }) => {
  return (
    <>
      <path
        d="M3.73096 20.1289H22.2502"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9331 20.1288V16.3958C10.9331 15.9008 11.1499 15.426 11.5358 15.076C11.9217 14.7259 12.4451 14.5293 12.9908 14.5293C13.5365 14.5293 14.0599 14.7259 14.4458 15.076C14.8317 15.426 15.0485 15.9008 15.0485 16.3958V20.1288"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9331 5.19678H15.0485"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9907 3.33057V7.99679"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1639 13.596V20.1288M6.8177 20.1288V13.596V20.1288ZM4.76001 15.4625L12.9908 7.99658L21.2215 15.4625H4.76001Z"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};
