import { Props, strokeColors } from '.';

export const IconSearch = ({
  width = 15,
  height = 15,
  className = '',
  color = 'nad-alps-night',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 30 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25Z"
        className={`${strokeColors[color]} ${className}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 25.25L17.75 17.75"
        className={`${strokeColors[color]} ${className}`}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
