import { fillColors, iconColorOptions, Props } from '.';

export const ChevronDown = (props: Props) => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.size == 'S' && <SizeS color={props.color || 'nad-blue'} />}
    </svg>
  );
};

export const SizeS = (props: { color: iconColorOptions }) => {
  return (
    <>
      <path
        d="M9.65377 5.59623L15 0.25H0L5.34623 5.59623L7.5 7.75L9.65377 5.59623Z"
        className={fillColors[props.color]}
        fill="#003B5C"
      />
    </>
  );
};
