import { fillColors, Props } from '.';

export const IconDashFill = ({
  width = 16,
  height = 16,
  color = 'nad-blue',
  className,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${fillColors[color]} ${className}`}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" />
      <path
        d="M8 5H2"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
