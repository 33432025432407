import { Props, fillColors } from '.';

export const IconInterrogationFill = ({
  width = 20,
  height = 20,
  color = 'nad-alps-night',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="8" className={fillColors[color]} />
      <path
        d="M6.86391 8.997C6.86391 8.755 6.89691 8.53867 6.96291 8.348C7.02891 8.15733 7.13524 7.97767 7.28191 7.809C7.43591 7.64033 7.63757 7.468 7.88691 7.292C8.10691 7.13067 8.28291 6.98767 8.41491 6.863C8.55424 6.73833 8.65324 6.61367 8.71191 6.489C8.77791 6.36433 8.81091 6.225 8.81091 6.071C8.81091 5.83633 8.72291 5.66033 8.54691 5.543C8.37824 5.41833 8.13991 5.356 7.83191 5.356C7.52391 5.356 7.21957 5.40367 6.91891 5.499C6.61824 5.59433 6.31024 5.72267 5.99491 5.884L5.41191 4.707C5.77124 4.509 6.15991 4.34767 6.57791 4.223C6.99591 4.09833 7.45424 4.036 7.95291 4.036C8.71557 4.036 9.30591 4.21933 9.72391 4.586C10.1492 4.95267 10.3619 5.41833 10.3619 5.983C10.3619 6.28367 10.3142 6.544 10.2189 6.764C10.1236 6.984 9.98057 7.18933 9.78991 7.38C9.59924 7.56333 9.36091 7.76133 9.07491 7.974C8.86224 8.128 8.69724 8.26 8.57991 8.37C8.46257 8.48 8.38191 8.58633 8.33791 8.689C8.30124 8.79167 8.28291 8.92 8.28291 9.074V9.393H6.86391V8.997ZM6.68791 11.23C6.68791 10.8927 6.77957 10.658 6.96291 10.526C7.14624 10.3867 7.36991 10.317 7.63391 10.317C7.89057 10.317 8.11057 10.3867 8.29391 10.526C8.47724 10.658 8.56891 10.8927 8.56891 11.23C8.56891 11.5527 8.47724 11.7873 8.29391 11.934C8.11057 12.0733 7.89057 12.143 7.63391 12.143C7.36991 12.143 7.14624 12.0733 6.96291 11.934C6.77957 11.7873 6.68791 11.5527 6.68791 11.23Z"
        fill="white"
      />
    </svg>
  );
};
