import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';

const metaMessages = defineMessages({
  description: {
    id: 'C8GteF',
    defaultMessage:
      'Adventist Giving provides a way for you to return tithe and offerings to your local Adventist church from any computer or mobile device.',
    description: 'HTML meta description of the site',
  },
});

export const PageMeta = () => {
  const intl = useIntl();
  // @see https://cheatsheetseries.owasp.org/cheatsheets/Clickjacking_Defense_Cheat_Sheet.html#best-for-now-legacy-browser-frame-breaking-script
  const isClientSide = typeof window !== 'undefined';
  const hideSite = isClientSide && window.self !== window.top;
  return (
    <Head>
      <title>AdventistGiving</title>
      <meta name="application-name" content="AdventistGiving" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="AdventistGiving" />
      <meta
        name="description"
        content={intl.formatMessage(metaMessages.description)}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/icons/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#003B5C" />

      <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/apple-touch-icon.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/assets/favicons/manifest.json" />
      <link
        rel="mask-icon"
        href="/icons/safari-pinned-tab.svg"
        color="#da532c"
      />
      <link rel="shortcut icon" href="/favicon.ico" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://adventistgiving.org" />
      <meta name="twitter:title" content="AdventistGiving" />
      <meta
        name="twitter:description"
        content={intl.formatMessage(metaMessages.description)}
      />
      <meta
        name="twitter:image"
        content="https://adventistgiving.org/icons/android-chrome-192x192.png"
      />
      <meta name="twitter:creator" content="@AdventistGiving" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="AdventistGiving" />
      <meta
        property="og:description"
        content={intl.formatMessage(metaMessages.description)}
      />
      <meta property="og:site_name" content="AdventistGiving" />
      <meta property="og:url" content="https://adventistgiving.org" />
      <meta
        property="og:image"
        content="https://adventistgiving.org/icons/apple-touch-icon.png"
      />
      {/* https://cheatsheetseries.owasp.org/cheatsheets/Clickjacking_Defense_Cheat_Sheet.html#best-for-now-legacy-browser-frame-breaking-script */}
      {hideSite && <style>{`body{display:none !important;}`}</style>}
    </Head>
  );
};
