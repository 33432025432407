import { Props, fillColors } from '.';

export const IconArrowLeft = ({
  width = 13,
  height = 13,
  color = 'nad-alps-night',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1275_46946)">
        <path
          d="M6.50055 12.8828L8.29455 11.0498L5.04455 7.79981L12.9355 7.79981L12.9355 5.19981L5.04455 5.19981L8.29455 1.94981L6.50055 0.116813L0.000547967 6.49981L6.50055 12.8828Z"
          className={fillColors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_1275_46946">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(13 13) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
