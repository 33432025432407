import { Fragment, MutableRefObject } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '../../atoms/button/button';
import { IconClose } from '../../atoms/icons';

export const modalMessages = defineMessages({
  close: {
    id: 'IlfbdN',
    defaultMessage: 'close',
    description: 'Aria label for close buttons on modals',
  },
});

type sizes = 'XS' | 'S' | 'M' | 'L';

type variants = 'normal' | 'paddingLess';

const sizesList = {
  XS: 'md:w-6/12 lg:w-4/12',
  S: 'md:w-[615px]',
  M: 'md:w-[820px]',
  L: 'md:w-[1000px]',
};

interface Props {
  isOpen?: boolean;
  children: ReactNode;
  size?: sizes;
  paddingLess?: boolean;
  variant?: variants;
  className?: string;
  initialFocus?: MutableRefObject<null>;
  onClose: () => void;
  hideClose?: boolean;
  autoFocus?: boolean;
}

const Modal = ({
  isOpen = false,
  size = 'M',
  variant = 'normal',
  className = '',
  initialFocus,
  hideClose = false,
  autoFocus = false,
  ...otherProps
}: Props) => {
  const intl = useIntl();
  return (
    <Transition.Root show={isOpen} as={Fragment} {...otherProps}>
      <Dialog
        as="div"
        initialFocus={initialFocus}
        className="relative z-20"
        static
        autoFocus={autoFocus}
        onClose={(value) => otherProps.onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-80"
          leave="ease-in duration-200"
          leaveFrom="opacity-80"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-nad-alps-night opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="md:container modal-container md:mx-auto flex md:items-center items-end justify-center h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-y-auto bg-white text-left shadow-xl transition-all sm:my-8 w-full ${sizesList[size]} max-h-[95%] flex flex-col justify-between ${className}`}
              >
                {variant === 'normal' && (
                  <div>
                    <div
                      className={`flex flex-col justify-center items-center pt-16 ${
                        !otherProps.paddingLess && 'md:pb-16 pb-28'
                      }`}
                    >
                      {otherProps.children}
                    </div>
                    {hideClose !== true && (
                      <Button
                        variant="icon"
                        onClick={() => otherProps.onClose()}
                        className={`p-4 absolute top-3 right-3`}
                        aria-label={intl.formatMessage(modalMessages.close)}
                        data-testid="modal-close"
                      >
                        <IconClose width={11} height={11} className="w-4 h-4" />
                      </Button>
                    )}
                  </div>
                )}
                {variant === 'paddingLess' && (
                  <div className="w-full mx-auto flex flex-col justify-between min-h-full">
                    {otherProps.children}
                    {hideClose !== true && (
                      <Button
                        variant="icon"
                        onClick={() => otherProps.onClose()}
                        className={`hidden md:block p-4 absolute top-3 right-3 z-10`}
                        aria-label={intl.formatMessage(modalMessages.close)}
                        data-testid="modal-close"
                      >
                        <IconClose width={11} height={11} className="w-4 h-4" />
                      </Button>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
