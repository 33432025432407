import useSWR, { Fetcher, KeyedMutator, SWRConfiguration } from 'swr';
import { signOut } from 'next-auth/react';
import ServerError from '@errors/ServerError';

export interface ApiHook<T> {
  data?: T;
  error?: any;
  isLoading: boolean;
  isValidating?: boolean;
  mutate: KeyedMutator<T>;
}

export function createFetcher<T>(): Fetcher<T, any> {
  const fetcher: Fetcher<T, any> = (url: string) =>
    fetch(url).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const error = new ServerError(
          `An error occurred for the url ${url}`,
          res.status
        );
        error.info = await res.json();
        if (res.status === 401) {
          signOut();
        } else {
          throw error;
        }
      }
    });
  return fetcher;
}

interface CreateAPIEndpoint {
  url: string;
  shouldFetch?: boolean;
  options?: SWRConfiguration;
}
export function useCreateSWREndpoint<T>({
  url,
  shouldFetch = true,
  options,
}: CreateAPIEndpoint): ApiHook<T> {
  const fetcher = createFetcher<T>();
  return useSWR(shouldFetch ? url : null, fetcher, options);
}

export type QueryParams = {
  [key: string]: string | number | boolean | undefined;
};

export function buildUrlQuery(params: QueryParams) {
  const queryParams: string[] = [];
  for (const key in params) {
    if (params[key]) {
      queryParams.push(`${key}=${encodeURIComponent(params[key]!)}`);
    }
  }
  const query = queryParams.join('&');
  return query.length ? `?${query}` : '';
}
