import { Props, fillColors } from '.';

export const IconClose = ({
  width = 10,
  height = 10,
  color = 'nad-alps-night',
  className = '',
  ...otherProps
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 11 11`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
      {...otherProps}
    >
      <path d="M9.90984 1.49984L8.49984 0.0898438L4.99984 3.58984L1.49984 0.0898438L0.0898438 1.49984L3.58984 4.99984L0.0898438 8.49984L1.49984 9.90984L4.99984 6.40984L8.49984 9.90984L9.90984 8.49984L6.40984 4.99984L9.90984 1.49984Z" />
    </svg>
  );
};
