import { Props, strokeColors, iconColorOptions } from '.';

export const IconWorld = ({
  width = 35,
  height = 35,
  color = 'nad-alps-night',
  size = 'M',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {size == 'S' && <SizeS color={color} width={width} height={height} />}
      {size == 'M' && <SizeM color={color} width={width} height={height} />}
    </svg>
  );
};

const SizeM = (props: {
  color: iconColorOptions;
  width: number;
  height: number;
}) => {
  return (
    <>
      <path
        d="M17.5 30.625C24.7487 30.625 30.625 24.7487 30.625 17.5C30.625 10.2513 24.7487 4.375 17.5 4.375C10.2513 4.375 4.375 10.2513 4.375 17.5C4.375 24.7487 10.2513 30.625 17.5 30.625Z"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 13.125H29.75"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 21.875H29.75"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.771 4.375C14.3142 8.31193 13.0117 12.8594 13.0117 17.5C13.0117 22.1406 14.3142 26.6881 16.771 30.625"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2305 4.375C20.6873 8.31193 21.9898 12.8594 21.9898 17.5C21.9898 22.1406 20.6873 26.6881 18.2305 30.625"
        className={`${strokeColors[props.color]}`}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

const SizeS = (props: {
  color: iconColorOptions;
  width: number;
  height: number;
}) => {
  return (
    <>
      <g clipPath="url(#clip0_3197_84006)">
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#003B5C"
          className={`${strokeColors[props.color]}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59961 9H20.3996"
          stroke="#003B5C"
          className={`${strokeColors[props.color]}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59961 15H20.3996"
          stroke="#003B5C"
          className={`${strokeColors[props.color]}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4997 3C9.81501 5.69961 8.92188 8.81787 8.92188 12C8.92188 15.1821 9.81501 18.3004 11.4997 21"
          stroke="#003B5C"
          className={`${strokeColors[props.color]}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 3C14.1847 5.69961 15.0778 8.81787 15.0778 12C15.0778 15.1821 14.1847 18.3004 12.5 21"
          stroke="#003B5C"
          className={`${strokeColors[props.color]}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3197_84006">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};
