import { SVGProps } from 'react';

export type iconColorOptions =
  | 'primary'
  | 'none'
  | 'white'
  | 'nad-alps-earth'
  | 'nad-alps-night'
  | 'nad-alps-night-2'
  | 'nad-scarlett'
  | 'nad-blue'
  | 'nad-ming'
  | 'nad-green'
  | 'nad-white'
  | 'nad-orange';

export const strokeColors = {
  primary: 'stroke-primary',
  none: 'stroke-none',
  white: 'stroke-white',
  'nad-alps-earth': 'stroke-nad-alps-earth',
  'nad-alps-night': 'stroke-nad-alps-night',
  'nad-alps-night-2': 'stroke-nad-alps-night-2',
  'nad-blue': 'stroke-nad-blue',
  'nad-scarlett': 'stroke-nad-scarlett',
  'nad-ming': 'stroke-nad-ming',
  'nad-green': 'stroke-nad-green',
  'nad-orange': 'stroke-nad-orange',
  'nad-white': 'stroke-nad-white',
};

export type iconSizes = 'S' | 'M';

export const fillColors = {
  primary: 'fill-primary',
  none: 'fill-none',
  white: 'fill-white',
  'nad-alps-earth': 'fill-nad-alps-earth',
  'nad-alps-night': 'fill-nad-alps-night',
  'nad-alps-night-2': 'fill-nad-alps-night-2',
  'nad-blue': 'fill-nad-blue',
  'nad-scarlett': 'fill-nad-scarlett',
  'nad-ming': 'fill-nad-ming',
  'nad-green': 'fill-nad-green',
  'nad-orange': 'fill-nad-orange',
  'nad-white': 'fill-nad-white',
};

export interface Props extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: iconColorOptions;
  className?: string;
  color2?: iconColorOptions;
  size?: iconSizes;
  fill?: iconColorOptions;
  fillClassName?: string;
}
export { IconBank } from './bank';
export { IconBuild } from './build';
export { IconWorld } from './world';
export { IconTrash } from './trash';
export { IconTrashBold } from './trash-bold';
export { IconDollar } from './dollar';
export { IconDollarFill } from './dollar-fill';
export { IconSearch } from './search';
export { IconChurch } from './church';
export { IconLanguage } from './language';
export { IconArrowLeft } from './arrow-left';
export { ChevronDown } from './chevron-down';
export { IconChevronLeft } from './chevron-left';
export { IconChevronRight } from './chevron-right';
export { IconArrowRight } from './arrow-right';
export { IconArrowRightFill } from './arrow-right-fill';
export { IconArrowBottom } from './arrow-bottom';
export { IconInterrogation } from './interrogation';
export { IconInterrogationFill } from './interrogation-fill';
export { IconChevronRoundedLeft } from './chevron-rounded-left';
export { IconChevronRoundedRight } from './chevron-rounded-right';
export { IconTithe } from './tithe';
export { IconClose } from './close';
export { IconPencil } from './pencil';
export { IconPencilFill } from './pencil-fill';
export { IconCheckFill } from './check-fill';
export { IconCheck } from './check';
export { IconDashFill } from './dash-fill';
export { IconWarning } from './warning';
export { IconFailed } from './failed';
export { IconArrowUp } from './arrow-up';
export { IconArrowUpFill } from './arrow-up-fill';
export { IconUser } from './user';
export { IconSort } from './sort';
export { IconSortHeader } from './sort-header';
export { IconUpload } from './upload';
export { IconDots } from './dots';
export { IconCalendar } from './calendar';
export { IconArrowDown } from './arrow-down';
