import { Props, fillColors } from '.';

export const IconArrowUp = ({
  width = 15,
  height = 15,
  color = 'nad-alps-night',
}: Props) => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38501 2.37501L0.135008 7.62501L2.25001 9.74001L7.50001 4.49001L12.75 9.74001L14.865 7.62501L9.61501 2.37501L7.50001 0.260011L5.38501 2.37501Z"
        className={`${fillColors[color]}`}
      />
    </svg>
  );
};
