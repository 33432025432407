import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconArrowBottom } from '../../atoms/icons';
import { Text } from '../../atoms/text/text';
import UserMainNavMenuItems from '../user-main-nav-menu-items/user-main-nav-menu-items';
import { useCurrentUser } from '@hooks/use-user';

const UserMainNav = () => {
  const { data: user } = useCurrentUser();
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="inline-flex w-full justify-center p-2"
              data-testid="home-nav-menu"
            >
              <div className="flex items-center space-x-2 relative group">
                <Text
                  size="XS"
                  color={open ? 'nad-ming' : 'nad-alps-night'}
                  className="font-sans font-bold uppercase group-hover:text-nad-ming"
                >
                  <FormattedMessage
                    id="iQu480"
                    defaultMessage="{name}'s Account"
                    description="Main header link when a user is logged in. i.e. Michael's Account"
                    values={{
                      name: user ? `${user.first_name}` : '',
                    }}
                  />
                </Text>
                <IconArrowBottom
                  color={open ? 'nad-ming' : 'nad-alps-night'}
                  className={`transition-transform group-hover:fill-nad-ming ${
                    open ? 'rotate-180' : ''
                  }`}
                />
                {user && user.unread_notification_count > 0 && (
                  <span className="rounded-full bg-nad-ming w-4 h-4 flex justify-center items-center text-white text-[9px] font-bold font-sans absolute -top-3 -right-3">
                    {user?.unread_notification_count}
                  </span>
                )}
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-20 right-0 mt-2 w-80 origin-top-right bg-white shadow focus:outline-none border-4 border-nad-light-blue border-opacity-25">
              {user && <UserMainNavMenuItems user={user} />}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default UserMainNav;
