export default class ServerError extends Error {
  status: number;
  info: any;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'ServerError';
  }
}
