import { Props, strokeColors } from '.';

interface CalendarProps extends Props {
  selected?: boolean;
}

export const IconCalendar = ({
  width = 17,
  height = 18,
  className = '',
  color = 'nad-blue',
  selected,
}: CalendarProps) => {
  if (selected) {
    return (
      <CalendarSelected
        width={width}
        height={height}
        className={className}
        color={color}
      />
    );
  }
  return (
    <Calendar
      width={width}
      height={height}
      className={className}
      color={color}
    />
  );
};

const Calendar = ({
  width,
  height,
  className = '',
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className} ${strokeColors[color]}`}
      viewBox="1 1 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10949_13983)">
        <path
          d="M12.7502 4.04163H4.25016C3.46776 4.04163 2.8335 4.67589 2.8335 5.45829V13.9583C2.8335 14.7407 3.46776 15.375 4.25016 15.375H12.7502C13.5326 15.375 14.1668 14.7407 14.1668 13.9583V5.45829C14.1668 4.67589 13.5326 4.04163 12.7502 4.04163Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3335 2.625V5.45833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.6665 2.625V5.45833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.8335 8.29163H14.1668"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10949_13983">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarSelected = ({
  width,
  height,
  className = '',
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${className} ${strokeColors[color]}`}
      viewBox="1 1 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10949_14017)">
        <path
          d="M12.7502 4.04163H4.25016C3.46776 4.04163 2.8335 4.67589 2.8335 5.45829V13.9583C2.8335 14.7407 3.46776 15.375 4.25016 15.375H12.7502C13.5326 15.375 14.1668 14.7407 14.1668 13.9583V5.45829C14.1668 4.67589 13.5326 4.04163 12.7502 4.04163Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3335 2.625V5.45833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.6665 2.625V5.45833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="3.5" y="9" width="10" height="6" fill="#003B5C" />
        <path
          d="M2.8335 8.29163H14.1668"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10949_14017">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
