import { fillColors, Props } from '.';

export const IconChevronLeft = ({
  width = 12,
  height = 16,
  className = '',
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
    >
      <mask id="path-1-inside-1_4655_13634" fill="white">
        <path d="M3.19869 10.2555L8.79869 15.8555L11.0547 13.5995L5.45469 7.99947L11.0547 2.39947L8.79869 0.143471L3.19869 5.74347L0.942688 7.99947L3.19869 10.2555Z" />
      </mask>
      <path d="M3.19869 10.2555L8.79869 15.8555L11.0547 13.5995L5.45469 7.99947L11.0547 2.39947L8.79869 0.143471L3.19869 5.74347L0.942688 7.99947L3.19869 10.2555Z" />
    </svg>
  );
};
