import { Props, strokeColors } from '.';

export const IconBank = ({
  width = 19,
  height = 20,
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.625 19H17.375"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.625 8H17.375"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 4L9.5 1L15.625 4"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 8V19"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 8V19"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6 12V15"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 12V15"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13 12V15"
        className={strokeColors[color]}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
