import { env } from 'next-runtime-env';

const get = (key: string) => {
  return env(key);
};

/**
 * Required to format the export like this so we can mock it in Cypress
 */
const res = {
  get,
};

export default res;
