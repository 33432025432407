import { Menu } from '@headlessui/react';
import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { mutate } from 'swr';
import { fetchPost } from '@fetch/helpers';
import { mutateNotifications } from '@hooks/use-notifications';
import { IconCheck } from '@components/atoms/icons';

interface Props {
  isMobileMenu?: boolean;
  onUpdate?: () => void;
}

export type LocaleOption = 'en' | 'es' | 'fr';

interface LanguageOption {
  locale: LocaleOption;
  text: string;
}
const languages: LanguageOption[] = [
  {
    locale: 'en',
    text: 'ENGLISH',
  },
  {
    locale: 'es',
    text: 'ESPAÑOL',
  },
  {
    locale: 'fr',
    text: 'FRANÇAIS',
  },
];

const LanguageMenuItems = ({ isMobileMenu = false, onUpdate }: Props) => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const locale = router.locale || 'en';

  const updateLocale = async (locale: string) => {
    await fetchPost({ url: `/api/locale`, data: { locale } });
    router.push({ pathname, query }, asPath, { locale });
    // mutate any endpoints that changed based on locale
    mutateNotifications();
    mutate('/api/v3/offering_of_the_week');
    onUpdate && onUpdate();
  };

  const classes = isMobileMenu
    ? 'text-s text-left px-2 py-4'
    : 'text-xs px-4 py-2.5 flex items-center justify-between';
  return (
    <>
      {languages.map((item) => (
        <Menu.Item key={item.locale}>
          {({ active }) => (
            <button
              onClick={() => updateLocale(item.locale)}
              data-testid="mobile-nav-language-item"
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              } w-full ${classes}  font-bold`}
            >
              {item.text}
              {!isMobileMenu && locale === item.locale && (
                <IconCheck color="nad-ming" />
              )}
            </button>
          )}
        </Menu.Item>
      ))}
    </>
  );
};

export default LanguageMenuItems;
