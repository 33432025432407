import { fillColors, Props } from '.';
export const IconDollarFill = ({
  width = 16,
  height = 16,
  color = 'nad-green',
  className,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${fillColors[color]} ${className}`}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5.5" r="5" />
      <g clipPath="url(#clip0_3872_107479)">
        <path
          d="M6.63222 4.11095C6.5631 3.91485 6.43689 3.74392 6.26981 3.62016C6.10273 3.4964 5.90244 3.42547 5.69472 3.4165H4.30583C4.02956 3.4165 3.76461 3.52625 3.56926 3.7216C3.37391 3.91695 3.26416 4.1819 3.26416 4.45817C3.26416 4.73444 3.37391 4.99939 3.56926 5.19474C3.76461 5.39009 4.02956 5.49984 4.30583 5.49984H5.69472C5.97098 5.49984 6.23593 5.60958 6.43129 5.80493C6.62664 6.00028 6.73638 6.26524 6.73638 6.5415C6.73638 6.81777 6.62664 7.08272 6.43129 7.27807C6.23593 7.47342 5.97098 7.58317 5.69472 7.58317H4.30583C4.0981 7.5742 3.89781 7.50327 3.73073 7.37951C3.56366 7.25575 3.43744 7.08482 3.36833 6.88873"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 7.58333V8.625M5 2.375V3.41667V2.375Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3872_107479">
          <rect
            width="8.33333"
            height="8.33333"
            fill="white"
            transform="translate(0.833496 1.33325)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
