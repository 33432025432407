import { Dialog, Transition, Menu } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  IconArrowLeft,
  IconChevronRight,
  IconClose,
  IconLanguage,
} from '@components/atoms/icons';
import { Text } from '@components/atoms/text/text';
import { Link } from '@components/atoms/link/link';
import DonateButton from '@components/molecules/donate-button/donate-button';
import { Button } from '@components/atoms/button/button';
import UserMainNavMenuItems from '@components/molecules/user-main-nav-menu-items/user-main-nav-menu-items';
import { useCurrentUser } from '@hooks/use-user';
import LanguageMenuItems from '@components/molecules/language-menu-items/language-menu-items';
import { modalMessages } from '@components/molecules/modal/modal';

interface Props {
  onMobileNavToggle?: (param: boolean) => void;
  showMobileNav: boolean;
}

const messages = defineMessages({
  navLabel: {
    id: 'gLlRmL',
    defaultMessage: 'site navigation',
    description: 'Screen reader label for site navigation.',
  },
});

const MobileNav = ({ onMobileNavToggle, showMobileNav }: Props) => {
  const intl = useIntl();
  const router = useRouter();
  const { data: user } = useCurrentUser();
  const [showLanguage, setShowLanguage] = useState(false);

  return (
    <Transition.Root show={showMobileNav} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => onMobileNavToggle && onMobileNavToggle(false)}
        data-testid="mobile-nav-toogle"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl">
                    <Button
                      variant="icon"
                      onClick={() =>
                        onMobileNavToggle && onMobileNavToggle(false)
                      }
                      aria-label={intl.formatMessage(modalMessages.close)}
                      className={`p-4 mt-2 mr-2 sticky self-end`}
                    >
                      <IconClose
                        width={20}
                        height={20}
                        className="w-5 h-5"
                        data-testid="mobile-nav-close-icon"
                      />
                    </Button>
                    <nav
                      className="container-sm mt-4"
                      aria-label={intl.formatMessage(messages.navLabel)}
                    >
                      {!showLanguage ? (
                        <>
                          <Menu>
                            <Menu.Items static>
                              <div className="w-full flex justify-between mb-6">
                                <Menu.Item
                                  as={Link}
                                  href="/donate"
                                  onClick={() =>
                                    onMobileNavToggle &&
                                    onMobileNavToggle(false)
                                  }
                                >
                                  <DonateButton
                                    data-cy="header-nav"
                                    mobile={true}
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  as="div"
                                  className="flex flex-row space-x-1 p-2 items-center rounded-md text-nad-blue group cursor-pointer"
                                  onClick={() => setShowLanguage(true)}
                                  data-testid="mobile-nav-language-option"
                                >
                                  <div className="mt-0.5">
                                    <IconLanguage className="group-hover:fill-nad-blue" />
                                  </div>

                                  <Text
                                    size="S"
                                    color="nad-alps-night"
                                    className="font-sans font-bold group-hover:text-nad-blue uppercase"
                                  >
                                    {router.locale || 'en'}
                                  </Text>
                                  <div className="pl-2">
                                    <IconChevronRight color="nad-alps-night" />
                                  </div>
                                </Menu.Item>
                              </div>
                              {!user && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href="/login"
                                      onClick={() =>
                                        onMobileNavToggle &&
                                        onMobileNavToggle(false)
                                      }
                                      className={`${
                                        active
                                          ? 'bg-nad-light-blue text-nad-blue'
                                          : 'text-nad-blue'
                                      } font-sans group flex w-full uppercase justify-left tracking-wider px-3 py-4 text-xs font-bold self-left`}
                                    >
                                      <Text size="S" color="nad-blue">
                                        <FormattedMessage
                                          id="Q0LBWQ"
                                          defaultMessage="Sign In"
                                          description="Sign in link in mobile navigation"
                                        />
                                      </Text>
                                    </Link>
                                  )}
                                </Menu.Item>
                              )}
                              {!!user && (
                                <UserMainNavMenuItems
                                  isMobileMenu={true}
                                  onMobileNavToggle={onMobileNavToggle}
                                  user={user}
                                />
                              )}
                            </Menu.Items>
                          </Menu>
                        </>
                      ) : (
                        <>
                          <div className="w-full flex mb-10">
                            <div
                              className="flex items-center space-x-2 cursor-pointer"
                              onClick={() => setShowLanguage(false)}
                              data-testid="mobile-nav-close-language"
                            >
                              <IconArrowLeft color="nad-blue" />
                              <Text
                                size="S"
                                color="nad-blue"
                                className="font-sans uppercase font-bold group-hover:text-nad-blue"
                              >
                                <FormattedMessage
                                  id="4Xfvp+"
                                  defaultMessage="Back"
                                  description="Back link in mobile navigation"
                                />
                              </Text>
                            </div>
                          </div>
                          <Menu
                            as={'div'}
                            data-testid="mobile-nav-language-results"
                          >
                            <Menu.Items static>
                              <LanguageMenuItems
                                isMobileMenu={true}
                                onUpdate={() => {
                                  setShowLanguage(false);
                                  onMobileNavToggle && onMobileNavToggle(false);
                                }}
                              />
                            </Menu.Items>
                          </Menu>
                        </>
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileNav;
