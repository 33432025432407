import { Props, fillColors } from '.';

export const IconArrowRight = ({
  width = 13,
  height = 13,
  color = 'nad-alps-night',
  className = '',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 13 13`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.50141 0.117188L4.70741 1.95019L7.95741 5.20019H0.0664062V7.80019H7.95741L4.70741 11.0502L6.50141 12.8832L13.0014 6.50019L6.50141 0.117188Z"
        className={`${fillColors[color]} ${className}`}
      />
    </svg>
  );
};
