import dayjs from 'dayjs';
import { NextApiRequest } from 'next';
import { getToken } from 'next-auth/jwt';
import { TokenResponse } from '@models/http/token-response';
import { TokenRequestClientCredentials } from '@models/http/token-request';

interface FetchGETParams {
  url: string;
  headers?: { [key: string]: string };
}
interface FetchWithDataParams {
  url: string;
  data: any;
  headers?: { [key: string]: string };
}

export type RequestMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
export type RequestType =
  | 'public'
  | 'privileged'
  | 'privilegedClientOnly'
  | 'withAuth';

interface FetchParams {
  method: RequestMethod;
  url: string;
  data?: any;
  type?: RequestType;
  req?: NextApiRequest;
  headers?: { [key: string]: string };
}

interface FetchWithReq {
  req: NextApiRequest;
}

export const fetchPost = async (params: FetchWithDataParams) => {
  return makeRequest({ method: 'POST', ...params });
};

export const fetchPatch = async (params: FetchWithDataParams) => {
  return makeRequest({ method: 'PATCH', ...params });
};

export const fetchDelete = async (params: FetchGETParams) => {
  return makeRequest({ method: 'DELETE', ...params });
};

export const fetchPut = async (params: FetchWithDataParams) => {
  return makeRequest({ method: 'PUT', ...params });
};

export const fetchWithAuth = async (params: FetchGETParams & FetchWithReq) => {
  return makeRequest({ method: 'GET', type: 'withAuth', ...params });
};

export const postWithAuth = async (
  params: FetchWithDataParams & FetchWithReq
) => {
  return makeRequest({ method: 'POST', type: 'withAuth', ...params });
};

export const patchWithAuth = async (
  params: FetchWithDataParams & FetchWithReq
) => {
  return makeRequest({ method: 'PATCH', type: 'withAuth', ...params });
};

export const deleteWithAuth = async (params: FetchGETParams & FetchWithReq) => {
  return makeRequest({ method: 'DELETE', type: 'withAuth', ...params });
};

export const fetchPrivilegedRoute = async (
  params: FetchGETParams & FetchWithReq
) => {
  return makeRequest({ method: 'GET', type: 'privileged', ...params });
};

export const postPrivilegedRoute = async (
  params: FetchWithDataParams & FetchWithReq
) => {
  return makeRequest({ method: 'POST', type: 'privileged', ...params });
};

export const fetchPrivilegedClientOnlyRoute = async (
  params: FetchGETParams & FetchWithReq
) => {
  return makeRequest({
    method: 'GET',
    type: 'privilegedClientOnly',
    ...params,
  });
};

export const postPrivilegedClientOnlyRoute = async (
  params: FetchWithDataParams & FetchWithReq
) => {
  return makeRequest({
    method: 'POST',
    type: 'privilegedClientOnly',
    ...params,
  });
};

export const makeRequest = async ({
  method,
  url,
  data,
  type,
  req,
  headers = {},
}: FetchParams) => {
  const isServerSide = typeof window === 'undefined';
  headers['Content-Type'] = 'application/json';
  if (isServerSide) {
    if (req && req.headers?.['x-forwarded-for']) {
      headers['X-Forwarded-For'] = req.headers['x-forwarded-for'] as string;
    }
    if (type === 'privileged') {
      const accessToken = await getClientCredentials({ req });
      headers['Authorization'] = `Bearer ${accessToken}`;
    } else if (type === 'privilegedClientOnly') {
      const accessToken = await getClientCredentials({ allowUserToken: false });
      headers['Authorization'] = `Bearer ${accessToken}`;
    } else if (type === 'withAuth') {
      if (!req) {
        throw new Error('Missing request object');
      }
      const jwtToken = await getToken({ req });
      if (!jwtToken) {
        throw new MissingTokenError('Missing token');
      }
      headers['Authorization'] = `Bearer ${jwtToken.accessToken}`;
    }
    if (req && req.cookies && req.cookies['NEXT_LOCALE']) {
      headers['X-AdventistGiving-Lang'] = req.cookies['NEXT_LOCALE'];
    }
  }
  return fetch(url, {
    method: method,
    headers,
    body:
      method === 'POST' || method === 'PATCH' || method === 'PUT'
        ? JSON.stringify(data)
        : null,
  });
};

export class MissingTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
  }
}

export const parseHeadersToLog = (headers: any) => {
  const keys = [
    'host',
    'x-real-ip',
    'x-forwarded-for',
    'user-agent',
    'referer',
  ];
  const data: any = {};
  try {
    for (const key in headers) {
      if (keys.includes(key.toLowerCase())) {
        data[key] = headers[key];
      }
    }
  } catch (err) {
    console.log('Error prasing headers', err);
  }
  return data;
};

export const logError = (message: string = '', err?: any, headers?: any) => {
  console.log(
    dayjs().format(),
    message,
    err,
    headers ? parseHeadersToLog(headers) : 'No request headers'
  );
};

// TODO: can we store this in memory or will it expire?
interface ClientCredentials {
  accessToken?: string;
  expiresAt: number;
}
const clientCredentials: ClientCredentials = {
  accessToken: undefined,
  expiresAt: 0,
};

interface ClientCredentialsParams {
  req?: NextApiRequest;
  allowUserToken?: boolean;
}
const getClientCredentials = async ({
  req,
  allowUserToken = true,
}: ClientCredentialsParams) => {
  // prefer logged in session token if it exists
  if (req && allowUserToken) {
    const jwtToken = await getToken({ req });
    if (jwtToken) {
      return jwtToken.accessToken;
    }
  }

  if (
    clientCredentials.accessToken &&
    Date.now() < clientCredentials.expiresAt
  ) {
    return clientCredentials.accessToken;
  }
  const params: TokenRequestClientCredentials = {
    grant_type: 'client_credentials',
    scope: 'public privileged',
    client_id: process.env.API_CLIENT_ID!,
    client_secret: process.env.API_CLIENT_SECRET!,
    redirect_uri: process.env.API_REDIRECT_URI!,
  };
  const response = await fetchPost({
    url: `${process.env.API_URL}/v3/oauth/token`,
    data: params,
  });
  const responseData: TokenResponse = await response.json();
  if (response.ok) {
    clientCredentials.accessToken = responseData.access_token;
    clientCredentials.expiresAt = dayjs()
      .add(responseData.expires_in, 'seconds')
      .valueOf();
    return responseData.access_token;
  }
  throw new Error('Unabe to get client credentials');
};
