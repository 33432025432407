import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { SimpleTemplateContainer } from '@components/templates';
import { Text } from '@components/atoms/text/text';
import { Button } from '@components/atoms/button/button';

interface Params {
  forceReload?: boolean;
}

const ErrorPage = ({ forceReload = false }: Params) => {
  return (
    <SimpleTemplateContainer isContainer>
      <div className="w-full flex flex-col md:flex-row md:justify-between mb-10">
        <div className="w-full md:w-4/12">
          <Text as="h1" color="nad-blue" size="6XL" className="font-serif">
            500
          </Text>
          <Text color="nad-blue" size="L" className="font-serif">
            <FormattedMessage
              id="UMouRd"
              defaultMessage="We’re experiencing an internal problem. Please try again later."
              description="Message on 500 server error page."
            />
          </Text>
          <Button
            className="mt-6 inline-block"
            as={forceReload ? 'button' : 'link'}
            href="/"
            onClick={() => {
              if (forceReload) {
                window.location.href = '/';
              }
            }}
          >
            <FormattedMessage
              id="KVwSns"
              defaultMessage="Go back home"
              description="Button on 500 server error page to direct users to homepage."
            />
          </Button>
        </div>
        <div className="w-full md:w-7/12">
          <div className="cover w-full relative md:aspect-[16/10] aspect-[18/21]">
            <Image
              fill={true}
              sizes="100vw"
              className="object-cover mt-8"
              src="/noprecache/img/404.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </SimpleTemplateContainer>
  );
};

export default ErrorPage;
