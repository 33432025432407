import { FormattedMessage } from 'react-intl';
import { Heading } from '@components/atoms/heading/heading';
import { Text } from '@components/atoms/text/text';

const JavascriptDisabled = () => {
  return (
    <noscript>
      <div className="fixed inset-0 z-20 bg-nad-alps-night opacity-80" />
      <div className="fixed inset-0 z-20 overflow-y-auto">
        <div className="md:container modal-container md:mx-auto flex md:items-center items-end justify-center h-full">
          <div className="w-full md:w-auto flex flex-col justify-center items-center bg-white p-6 md:p-16">
            <Heading variant="h3" color="nad-blue" className="text-center">
              <FormattedMessage
                id="hdz7ku"
                defaultMessage="You have JavaScript disabled"
                description="Title message to users if javascript is disabled"
              />
            </Heading>
            <Text
              color="nad-alps-night"
              size="XS"
              className="mt-6 text-center max-w-[360px]"
            >
              <FormattedMessage
                id="gSunP7"
                defaultMessage="AdventistGiving uses JavaScript to run. Please enable JavaScript and refresh AdventistGiving to continue."
                description="Detailed message to users if javascript is disabled."
              />
            </Text>
          </div>
        </div>
      </div>
    </noscript>
  );
};

export default JavascriptDisabled;
