import { fillColors, Props } from '.';

export const IconWarning = ({
  width = 16,
  height = 16,
  color = 'nad-orange',
  className = '',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
    >
      <path d="M1.11545 8.95498H8.89625C9.07761 8.95371 9.25589 8.90808 9.41556 8.82208C9.57523 8.73608 9.71143 8.61232 9.81228 8.46159C9.91313 8.31086 9.97557 8.13774 9.99415 7.95734C10.0127 7.77694 9.98689 7.59473 9.91887 7.42661L5.97289 0.618403C5.87677 0.444667 5.73586 0.299851 5.56482 0.19901C5.39378 0.0981683 5.19885 0.0449829 5.00029 0.0449829C4.80174 0.0449829 4.60681 0.0981683 4.43577 0.19901C4.26473 0.299851 4.12382 0.444667 4.02769 0.618403L0.081713 7.42661C0.0149877 7.59088 -0.0114716 7.76873 0.00454407 7.94531C0.0205597 8.1219 0.0785856 8.29209 0.173777 8.44167C0.268969 8.59126 0.398564 8.7159 0.551744 8.8052C0.704923 8.8945 0.877242 8.94585 1.05431 8.95498" />
      <line
        x1="5.04541"
        y1="3.27222"
        x2="5.04541"
        y2="4.99949"
        stroke="white"
        strokeLinecap="round"
      />
      <circle cx="4.99996" cy="6.86318" r="0.454545" fill="white" />
    </svg>
  );
};
