import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { Button } from '../../atoms/button/button';
import Modal from '../../molecules/modal/modal';
import { Heading } from '@components/atoms/heading/heading';
import { useCurrentUser } from '@hooks/use-user';

const REDIRECT_URL = '/logout?sessionEnded';

const timeout = 1000 * 60 * 15; // 15 minutes
const promptBeforeIdle = 1000 * 60 * 5; // 5 minutes

export const GuestIdleTimer = () => {
  const router = useRouter();
  const { data: user } = useCurrentUser();
  const isGuest = user?.status === 'guest';

  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setOpen(false);
    router.push(REDIRECT_URL);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startManually: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  useEffect(() => {
    if (isGuest) {
      activate();
    } else {
      pause();
    }
  }, [isGuest, activate, pause]);

  if (!isGuest) {
    return <></>;
  }

  return (
    <>
      <TimerModal
        isOpen={open}
        close={handleStillHere}
        timeRemaining={remaining}
      />
    </>
  );
};

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  timeRemaining: number;
}

const TimerModal = ({ isOpen, close, timeRemaining }: ModalProps) => {
  const router = useRouter();
  const date = new Date(0);
  date.setSeconds(timeRemaining);
  return (
    <Modal
      size="S"
      isOpen={isOpen}
      hideClose={true}
      data-testid="modal-idle-timer"
      onClose={() => {
        // left empty intentionally so that escape and other events cannot close the modal
      }}
    >
      <Heading
        variant="h3"
        color="nad-blue"
        className="text-center max-w-[290px]"
      >
        <FormattedMessage
          id="e5KUpB"
          defaultMessage="Your session will time out in {timeRemaining}"
          description="Edit profile modal title"
          values={{ timeRemaining: dayjs(date).format('m:ss') }}
        />
      </Heading>
      <div className="container text-center mt-8 space-y-4">
        <Button width="large" onClick={() => close()}>
          <FormattedMessage
            id="NZcJf8"
            defaultMessage="Continue Session"
            description="Continue session button"
          />
        </Button>
        <Button
          variant="link"
          width="large"
          onClick={() => {
            router.push(REDIRECT_URL);
            close();
          }}
        >
          <FormattedMessage
            id="YULrGd"
            defaultMessage="End Session"
            description="End session button"
          />
        </Button>
      </div>
    </Modal>
  );
};
