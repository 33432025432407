import { CurrentUser } from '@models/user';

export type HelpScoutBeacon = ((action: 'init', beaconId: string) => void) &
  ((action: 'search', query: string) => void) &
  ((action: 'navigate', route: string) => void) &
  ((action: 'identify', userObject: {}) => void) &
  ((action: 'prefill', formObject: {}) => void) &
  ((action: 'config', formObject: {}) => void) &
  ((action: 'event', eventObject: {}) => void) &
  ((action: 'suggest', criteria: string[]) => void) &
  ((
    action:
      | 'init'
      | 'destroy'
      | 'open'
      | 'close'
      | 'toggle'
      | 'article'
      | 'reset'
      | 'logout'
      | 'on'
      | 'off'
      | 'session-data'
  ) => void);
declare global {
  interface Window {
    Beacon: HelpScoutBeacon;
  }
}

const openHelpscoutContactForm = (
  config: any = {},
  currentUser?: CurrentUser
) => {
  fillUserInfo(config, currentUser);
  getBeacon()('open');
  getBeacon()('navigate', '/ask/');
};

const openHelpscout = (config: any = {}, currentUser?: CurrentUser) => {
  fillUserInfo(config, currentUser);
  getBeacon()('open');
  getBeacon()('navigate', '/');
};

const getBeacon = () => {
  return window.Beacon;
};

const fillUserInfo = (config: any = {}, currentUser?: CurrentUser) => {
  // getBeacon()('ini')
  if (currentUser) {
    config['name'] = `${currentUser.first_name} ${currentUser.last_name}`;
    config['email'] = currentUser.email;
    config['user-status'] = currentUser.status;
    const roles = currentUser.roles.map((role) => {
      let org = { id: role.organization.id, name: role.organization.name };
      return { type: role.type, org };
    });
    config['user-roles'] = JSON.stringify(roles);
  } else {
    config['user-status'] = 'logged-out';
  }
  getBeacon()('identify', config);
};

export const useHelpscout = () => {
  return { openContactForm: openHelpscoutContactForm, open: openHelpscout };
};
