import { fillColors, Props, strokeColors } from '.';

export const IconChevronRoundedLeft = ({
  width = 46,
  height = 46,
  color = 'nad-alps-night',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 46 46`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="44"
        height="44"
        rx="22"
        className={strokeColors[color]}
        strokeWidth="2"
      />
      <mask id="path-2-inside-1_1936_43481" className={fillColors[color]}>
        <path d="M19.1987 25.2555L24.7987 30.8555L27.0547 28.5995L21.4547 22.9995L27.0547 17.3995L24.7987 15.1435L19.1987 20.7435L16.9427 22.9995L19.1987 25.2555Z" />
      </mask>
      <path
        d="M19.1987 25.2555L24.7987 30.8555L27.0547 28.5995L21.4547 22.9995L27.0547 17.3995L24.7987 15.1435L19.1987 20.7435L16.9427 22.9995L19.1987 25.2555Z"
        className={fillColors[color]}
      />
    </svg>
  );
};
