import { defineMessages, useIntl } from 'react-intl';
import { Link } from '@components/atoms/link/link';
import { useHelpscout } from '@hooks/use-helpscout';
import { useCurrentUser } from '@hooks/use-user';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';

const messages = defineMessages({
  homepage: {
    id: 'jHLRL2',
    defaultMessage: 'Homepage',
    description: 'Footer link',
  },
  createAccount: {
    id: 'lbot3h',
    defaultMessage: 'Create an Account',
    description: 'Footer link',
  },
  myDashboard: {
    id: 'fPk3LK',
    defaultMessage: 'My Dashboard',
    description: 'Footer link',
  },
  enrollChurch: {
    id: 'tjvBv5',
    defaultMessage: 'Enroll My Church',
    description: 'Footer link',
  },
  contact: {
    id: 'y5Q8QH',
    defaultMessage: 'Contact Us',
    description: 'Footer link',
  },
  helpDesk: {
    id: 'nhqWIL',
    defaultMessage: 'Help Desk',
    description: 'Footer link',
  },
  navQuickLinks: {
    id: 'ipUvsW',
    defaultMessage: 'quick links',
    description: 'Screen reader label for footer quick links.',
  },

  navHelpLinks: {
    id: 'GnrK+X',
    defaultMessage: 'links for help',
    description: 'Screen reader label for footer links.',
  },
});
interface Props {
  className?: string;
}

const FooterNav = ({ className = '' }: Props) => {
  const { data: user } = useCurrentUser();
  const { openContactForm, open } = useHelpscout();
  const intl = useIntl();
  const links1 = [
    {
      href: '/',
      text: intl.formatMessage(messages.homepage),
    },
  ];
  if (user && user.status !== 'guest') {
    links1.push({
      href: '/account',
      text: intl.formatMessage(messages.myDashboard),
    });
  } else {
    links1.push({
      href: '/register',
      text: intl.formatMessage(messages.createAccount),
    });
  }
  const links2 = [
    {
      onClick: () => {
        openContactForm({}, user);
      },
      text: intl.formatMessage(messages.contact),
    },
    {
      onClick: () => {
        open({}, user);
      },
      text: intl.formatMessage(messages.helpDesk),
    },
  ];
  return (
    <div className={`${className}`}>
      <div className="w-full flex sm:flex-row flex-col sm:gap-4 tracking-wider text-nad-white font-bold uppercase">
        <nav
          aria-label={intl.formatMessage(messages.navQuickLinks)}
          className="sm:w-6/12 w-full"
        >
          <ul className="space-y-5 sm:space-y-6">
            {links1.map((link) => (
              <li key={link.href}>
                <Link href={link.href} className="hover:text-nad-ming">
                  <HeadingUppercase variant="h5">{link.text}</HeadingUppercase>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <nav
          aria-label={intl.formatMessage(messages.navHelpLinks)}
          className="mt-5 sm:mt-0 w-6/12"
        >
          <ul className="space-y-5 sm:space-y-6">
            {links2.map((link) => (
              <li key={link.text}>
                <a
                  href="#"
                  className="cursor-pointer hover:text-nad-ming"
                  onClick={(e) => {
                    e.preventDefault();
                    link.onClick();
                  }}
                >
                  <HeadingUppercase variant="h5">{link.text}</HeadingUppercase>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FooterNav;
