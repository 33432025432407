import { Props, strokeColors } from '.';

export const IconTithe = ({
  width = 13,
  height = 16,
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 13 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.35986L10.64 5.35986"
        className={strokeColors[color]}
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6.32031 2V14"
        className={strokeColors[color]}
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
