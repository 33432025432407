import { Props, fillColors } from '.';

export const IconLanguage = ({
  width = 19,
  height = 20,
  color = 'nad-alps-night',
  className = '',
}: Props) => {
  const classes = `${fillColors[color]} ${className}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 19 20`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.46 5.94C7.00003 5.79993 6.57996 5.64006 6.18 5.42004C6.16005 5.42004 6.16005 5.40009 6.13996 5.40009C5.67999 6.02005 5.39999 6.78012 5.34003 7.60016H7.22006C7.22006 7.01994 7.32009 6.45994 7.46002 5.94001L7.46 5.94Z"
        className={classes}
      />
      <path
        d="M7.62 5.38C7.82005 4.83996 8.07996 4.33996 8.38003 3.88C8.39998 3.86005 8.39998 3.83996 8.42007 3.82001C7.68011 4.02006 7.02011 4.39994 6.5 4.93997C6.51995 4.93997 6.51995 4.95992 6.54004 4.95992C6.88002 5.14003 7.23997 5.27995 7.61997 5.37999L7.62 5.38Z"
        className={classes}
      />
      <path
        d="M7.20004 8.17999H5.32001C5.38 9.00003 5.65999 9.73999 6.11994 10.3801C6.13989 10.3801 6.13989 10.3601 6.15997 10.3601C6.55994 10.1401 6.99998 9.98008 7.43998 9.84015C7.32 9.29997 7.21997 8.73997 7.20002 8.18001L7.20004 8.17999Z"
        className={classes}
      />
      <path
        d="M9.5 5.64002C9.95997 5.64002 10.38 5.59998 10.7999 5.52004C10.5399 4.84008 10.1999 4.24004 9.7599 3.74004C9.73995 3.72009 9.71986 3.7 9.71986 3.68005H9.49985H9.27984C9.25989 3.7 9.2398 3.72009 9.2398 3.74004C8.7998 4.24004 8.4598 4.84008 8.19977 5.52004C8.61998 5.59998 9.05998 5.64002 9.49998 5.64002H9.5Z"
        className={classes}
      />
      <path
        d="M11.38 5.37999C11.76 5.27996 12.12 5.14004 12.4599 4.95992C12.4799 4.95992 12.4799 4.93997 12.5 4.93997C11.98 4.39994 11.32 4.0199 10.5799 3.82001C10.5998 3.83996 10.5998 3.86005 10.6199 3.88C10.92 4.33996 11.1799 4.83996 11.38 5.38L11.38 5.37999Z"
        className={classes}
      />
      <path
        d="M7.78003 8.17999C7.79998 8.69996 7.88006 9.22003 8.01999 9.69996C8.50002 9.59993 9.00002 9.55989 9.50002 9.55989C10.02 9.55989 10.5 9.61988 10.9801 9.69996C11.1 9.21992 11.1801 8.71992 11.22 8.17999H7.78003Z"
        className={classes}
      />
      <path
        d="M9.50002 6.22002C8.98006 6.22002 8.50002 6.16003 8.01999 6.07996C7.87992 6.55999 7.79998 7.05999 7.78003 7.59992H11.22C11.2001 7.07996 11.12 6.55988 10.9801 6.07996C10.52 6.17998 10.02 6.22002 9.50004 6.22002H9.50002Z"
        className={classes}
      />
      <path
        d="M7.62003 10.38C7.23999 10.48 6.88007 10.62 6.5401 10.8001C6.52015 10.8001 6.52015 10.82 6.50006 10.82C7.02003 11.3601 7.68003 11.7401 8.42013 11.94C8.40018 11.92 8.40018 11.9 8.38009 11.88C8.08001 11.42 7.82009 10.92 7.62006 10.38L7.62003 10.38Z"
        className={classes}
      />
      <path
        d="M12.84 5.42001C12.44 5.62007 12 5.80005 11.56 5.93997C11.7001 6.45994 11.78 7.0199 11.8 7.59997H13.68C13.62 6.77994 13.34 6.03997 12.8801 5.3999C12.86 5.41999 12.84 5.41999 12.84 5.41999L12.84 5.42001Z"
        className={classes}
      />
      <path
        d="M15.82 0.5H3.18002C1.62002 0.5 0.359985 1.76004 0.359985 3.32004V12.14C0.359985 13.7 1.62002 14.9601 3.18002 14.9601H6.00006C5.98011 15.6801 5.84004 16.6201 5.46002 17.6201C5.24001 18.1601 5.40003 18.7601 5.84006 19.1601C6.1001 19.3801 6.41999 19.5001 6.74002 19.5001C6.97998 19.5001 7.19999 19.4401 7.41999 19.3201C9.38009 18.2199 11.02 16.3999 12.1 14.9801H15.82C17.38 14.9801 18.64 13.7201 18.64 12.1601V3.32009C18.64 1.76009 17.38 0.50005 15.82 0.50005L15.82 0.5ZM9.50002 12.66C6.85995 12.66 4.72002 10.5199 4.72002 7.88C4.72002 5.23993 6.85995 3.1 9.50002 3.1C12.1401 3.1 14.28 5.24007 14.28 7.88C14.28 10.5199 12.1401 12.66 9.50002 12.66Z"
        className={classes}
      />
      <path
        d="M9.5 10.12C9.04004 10.12 8.61997 10.16 8.20007 10.24C8.46012 10.9199 8.80011 11.52 9.24011 12.02C9.26006 12.0399 9.28015 12.06 9.28015 12.08H9.50016H9.72016C9.74011 12.06 9.7602 12.0399 9.7602 12.02C10.2002 11.52 10.5402 10.9199 10.8002 10.24C10.38 10.16 9.95999 10.12 9.50002 10.12H9.5Z"
        className={classes}
      />
      <path
        d="M11.38 10.38C11.18 10.92 10.9201 11.42 10.62 11.88C10.6 11.9 10.6 11.92 10.58 11.94C11.3199 11.7399 11.9799 11.34 12.5 10.82C12.4801 10.82 12.4801 10.8001 12.46 10.8001C12.12 10.62 11.7601 10.48 11.3801 10.38L11.38 10.38Z"
        className={classes}
      />
      <path
        d="M11.8 8.18003C11.7801 8.75996 11.7 9.29999 11.5601 9.84003C12.02 9.9801 12.4401 10.14 12.8401 10.36C12.86 10.36 12.86 10.3799 12.8801 10.3799C13.3401 9.75998 13.6201 8.99991 13.68 8.17987L11.8 8.18003Z"
        className={classes}
      />
    </svg>
  );
};
