import { Props, strokeColors } from '.';

export const IconBuild = ({
  width = 35,
  height = 35,
  className = '',
  color = 'nad-alps-night',
  size = 'M',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 23 23`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} ${strokeColors[color]}`}
    >
      <path
        d="M9.375 10.2316V4.09252C9.375 3.82116 9.48475 3.56091 9.6801 3.36902C9.87545 3.17714 10.1404 3.06934 10.4167 3.06934H20.8333C21.1096 3.06934 21.3746 3.17714 21.5699 3.36902C21.7653 3.56091 21.875 3.82116 21.875 4.09252V21.4867H13.5417M8.33333 9.20846L13.5417 14.3244V21.4867H8.33333V17.394V9.20846ZM8.33333 21.4867H3.125V14.3244L8.33333 9.20846V21.4867Z"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.542 7.16211V7.17234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.708 7.16211V7.17234"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.708 11.2549V11.2651"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.708 15.3477V15.3579"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
