import { FormattedMessage } from 'react-intl';
import { HTMLAttributes } from 'react';
import { IconDollar } from '../../atoms/icons';

interface Props extends HTMLAttributes<HTMLElement> {
  mobile?: boolean;
}

const DonateButton = ({ mobile, ...props }: Props) => {
  return (
    <div className="flex items-center space-x-1 group p-2 " {...props}>
      <IconDollar
        className={`${
          mobile
            ? `stroke-nad-blue`
            : `stroke-nad-alps-night group-hover:stroke-nad-ming`
        }`}
      />
      <span
        className={` font-sans  ${
          mobile
            ? `text-s group-hover:text-nad-blue text-nad-blue`
            : `text-xs text-nad-alps-night group-hover:text-nad-ming`
        } font-bold uppercase`}
      >
        <FormattedMessage
          id="+ZnZo0"
          defaultMessage="Donate"
          description="Donate link in header navigation"
        />
      </span>
    </div>
  );
};

export default DonateButton;
