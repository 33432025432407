import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorStackParser from 'error-stack-parser';
import ErrorPage from '../error-page/error-page';
import { fetchPost } from '@fetch/helpers';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    fetchPost({
      url: `/api/log-error`,
      data: {
        error: error.message,
        errorInfo,
        parsed: ErrorStackParser.parse(error),
      },
    });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage forceReload={true} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
