import { fillColors, Props } from '.';

export const IconDots = ({
  width = 3,
  height = 16,
  color = 'nad-scarlett',
  className = 'none',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
    >
      <path d="M0 0.5H3V3.5H0V0.5ZM0 15.5H3V12.5H0V15.5ZM0 9.5H3V6.5H0V9.5Z" />
    </svg>
  );
};
