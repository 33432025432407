import { Props, strokeColors } from '.';

export const IconCheck = ({
  width = 12,
  height = 12,
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${strokeColors[color]}`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79999 8.8L6.99999 11.2L11.2 2.8"
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
};
