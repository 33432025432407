import { fillColors, Props } from '.';

export const IconUpload = ({
  width = 12,
  height = 12,
  color = 'nad-blue',
  className = '',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${fillColors[color]} ${className}`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.1791 7.5H11.9942V11.7211H0V7.5H1.81513V9.92131H10.1791V7.5Z" />
      <path d="M2 4.4799L3.14868 5.58945L5.18534 3.5794L5.18534 8.5L6.81466 8.5L6.81466 3.5794L8.85132 5.58945L10 4.4799L6 0.5L2 4.4799Z" />
    </svg>
  );
};
