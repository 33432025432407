import { HtmlHTMLAttributes, ReactNode } from 'react';
import { TextColors, TEXT_COLOR_MAP } from '../text/text';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingVariantAs = HeadingVariant | 'p' | 'div';
type Font = 'sans' | 'serif';

interface Props extends HtmlHTMLAttributes<HTMLHeadingElement> {
  variant: HeadingVariant;
  as?: HeadingVariantAs;
  font?: Font;
  color?: TextColors;
  children: ReactNode;
}

export const Heading = ({
  variant,
  children,
  color,
  as,
  font = 'serif',
  className = '',
  ...props
}: Props) => {
  const classMap: Record<HeadingVariant, string> = {
    h1: h1Classes,
    h2: h2Classes,
    h3: h3Classes,
    h4: h4Classes,
    h5: h5Classes,
    h6: h6Classes,
  };
  const classes = classMap[variant];
  className = [className, color ? TEXT_COLOR_MAP[color] : ''].join(' ');
  const fontClass = font === 'serif' ? 'font-serif' : 'font-sans';
  const Component = as ?? 'div';
  return (
    <Component className={`${classes} ${fontClass} ${className}`} {...props}>
      {children}
    </Component>
  );
};
export const h1Classes = `text-3xl`;

export const h2Classes = `text-2xl`;

export const h3Classes = `text-xl`;

export const h4Classes = `text-l`;

export const h5Classes = `text-m`;

export const h6Classes = `text-s`;
