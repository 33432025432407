import { fillColors, Props } from '.';

export const IconCheckFill = ({
  width = 16,
  height = 16,
  color = 'nad-blue',
  className,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${fillColors[color]} ${className}`}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" />
      <g clipPath="url(#clip0_5199_162879)">
        <path
          d="M3.25 5L4.5 6.25L7 3.75"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5199_162879">
          <rect width="6" height="6" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
