import { Menu } from '@headlessui/react';
import LinkN from 'next/link';
import { defineMessages, useIntl } from 'react-intl';
import { IconArrowRight } from '../../atoms/icons';
import { Text } from '../../atoms/text/text';
import { Link } from '@components/atoms/link/link';
import {
  isFeaturedVideoAdmin,
  isMoneyMover,
  isTreasurer,
} from '@hooks/use-user';
import { useHelpscout } from '@hooks/use-helpscout';
import { CurrentUser } from '@models/user';
import PublicEnv from 'helpers/public-env';

interface Props {
  isMobileMenu?: boolean;
  onMobileNavToggle?: (param: boolean) => void;
  user: CurrentUser;
}

export const messages = defineMessages({
  profile: {
    id: 'cph3eM',
    defaultMessage: 'Profile Information',
    description: 'Label for account profile main menu item.',
  },
  donation: {
    id: 'QCGZ8C',
    defaultMessage: 'Donation History',
    description: 'Label for donation history main menu item.',
  },
  recurring: {
    id: 'ihaEVm',
    defaultMessage: 'Recurring Donations',
    description: 'Label for recurring donations main menu item.',
  },
  paymentMethods: {
    id: 'y7EUzt',
    defaultMessage: 'Payment Methods',
    description: 'Label for payment methods main menu item.',
  },
  treasurer: {
    id: '5vE2PN',
    defaultMessage: 'Treasurer Portal',
    description: 'Label for treasurer portal main menu item.',
  },
  dashboard: {
    id: 'NX6wbg',
    defaultMessage: 'My Dashboard',
    description: 'Label for dashboard main menu item.',
  },
  messages: {
    id: 'vfgv0+',
    defaultMessage: 'Messages',
    description: 'Label for messages main menu item.',
  },
  settings: {
    id: 'duvrEQ',
    defaultMessage: 'Settings',
    description: 'Label for settings main menu item.',
  },
  helpDesk: {
    id: '596QhT',
    defaultMessage: 'Help Desk',
    description: 'Label for help desk main menu item.',
  },
  downloadAchFiles: {
    id: 'TLLA67',
    defaultMessage: 'Download ACH Files',
    description: 'Label for ACH files main menu item.',
  },
  featuredVideoAdmin: {
    id: 'fpiqBn',
    defaultMessage: 'Featured Video Admin',
    description: 'Label for Feature Video Admin',
  },
  logout: {
    id: '+zfXDT',
    defaultMessage: 'Log Out',
    description: 'Label for logout main menu item.',
  },
});

interface LinkElement {
  href: string;
  key: keyof typeof messages;
}

const subMenuNav: LinkElement[] = [
  {
    href: '/account/profile',
    key: 'profile',
  },
  {
    href: '/account/donation-history',
    key: 'donation',
  },
  {
    href: '/account/recurring-donations',
    key: 'recurring',
  },
  {
    href: '/account/payment-methods',
    key: 'paymentMethods',
  },
];

const UserMainNavMenuItems = ({
  isMobileMenu = false,
  onMobileNavToggle,
  user,
}: Props) => {
  const { open } = useHelpscout();
  const intl = useIntl();
  const treasurerSpacingClasses = isMobileMenu
    ? 'px-3 py-4'
    : 'px-10 py-5 mb-2.5';
  const mainSpacingClasses = isMobileMenu ? 'px-3 py-4' : 'px-10 py-2.5';
  const subMenuSpacingClasses = isMobileMenu ? 'ml-3 my-4' : 'ml-10 my-2';
  const subMenuItemSpacingClasses = isMobileMenu
    ? 'px-2 py-4 first:pt-0 last:pb-0'
    : 'px-2 py-1';
  const showTreasurerLink = !!user && isTreasurer(user);
  const showMoneyMoverLink = !!user && isMoneyMover(user);
  const useNewTreasurerPortal =
    PublicEnv.get('NEXT_PUBLIC_USE_NEW_TREASURER_PORTAL') === 'true';
  const showVideoAdmin = !!user && isFeaturedVideoAdmin(user);
  return (
    <>
      {showTreasurerLink && (
        <Menu.Item>
          {({ active }) => (
            <LinkN
              href={`${
                useNewTreasurerPortal
                  ? '/treasurer'
                  : PublicEnv.get('NEXT_PUBLIC_URL_TREASURER_PORTAL')
              }`}
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active
                  ? 'bg-nad-blue text-nad-white'
                  : 'bg-nad-ming text-nad-white'
              } font-sans group w-full uppercase ${treasurerSpacingClasses} text-xs font-bold text-left w-full flex items-center justify-between`}
              data-testid="main-nav-treasurer"
            >
              <Text size="XS" color="nad-white">
                {intl.formatMessage(messages.treasurer)}
              </Text>
              <IconArrowRight color="white" />
            </LinkN>
          )}
        </Menu.Item>
      )}
      <Menu.Items
        static={isMobileMenu}
        className={`${isMobileMenu ? '' : 'my-4'}`}
      >
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/account"
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              } font-sans group flex w-full uppercase justify-left tracking-wider ${mainSpacingClasses} text-xs font-bold self-left`}
              data-testid="main-nav-dashboard"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.dashboard)}
              </Text>
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/account/messages"
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              }  font-sans group flex w-full uppercase justify-left tracking-wider ${mainSpacingClasses} text-xs font-bold self-left flex items-center space-x-2`}
              data-testid="main-nav-messages"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.messages)}
              </Text>
              {user && user.unread_notification_count > 0 && (
                <span className="rounded-full bg-nad-ming w-4 h-4 flex justify-center items-center text-white text-[9px] font-bold font-sans">
                  {user.unread_notification_count}
                </span>
              )}
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/account/profile"
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              }  font-sans group flex w-full uppercase justify-left tracking-wider ${mainSpacingClasses} text-xs font-bold self-left`}
              data-testid="main-nav-settings"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.settings)}
              </Text>
            </Link>
          )}
        </Menu.Item>
        <Menu.Items
          className={`${subMenuSpacingClasses} border-l border-nad-blue`}
          static={isMobileMenu}
        >
          {subMenuNav.map((item) => (
            <Menu.Item key={item.href}>
              {({ active }) => (
                <Link
                  href={item.href}
                  onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
                  className={`${
                    active ? 'border-nad-blue' : 'border-transparent'
                  } text-nad-blue text-s font-sans group flex w-full border-l-[3px] justify-left ${subMenuItemSpacingClasses} self-left`}
                  data-testid="main-nav-sub-item"
                >
                  {intl.formatMessage(messages[item.key])}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={(e) => {
                onMobileNavToggle && onMobileNavToggle(false);
                e.preventDefault();
                open();
              }}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              }  font-sans group flex w-full uppercase justify-left tracking-wider ${mainSpacingClasses} text-xs font-bold self-left`}
              data-testid="main-nav-helpdesk"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.helpDesk)}
              </Text>
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
      {showMoneyMoverLink && (
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/ach-files"
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              } border-t    py-5 font-sans group flex w-full uppercase justify-left tracking-wider ${
                isMobileMenu ? mainSpacingClasses : 'px-10 py-5'
              } text-xs font-bold self-left`}
              data-testid="main-nav-ach-files"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.downloadAchFiles)}
              </Text>
            </Link>
          )}
        </Menu.Item>
      )}
      {showVideoAdmin && (
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/account/featured-videos"
              onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
              className={`${
                active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
              } border-t    py-5 font-sans group flex w-full uppercase justify-left tracking-wider ${
                isMobileMenu ? mainSpacingClasses : 'px-10 py-5'
              } text-xs font-bold self-left`}
              data-testid="main-nav-ach-files"
            >
              <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
                {intl.formatMessage(messages.featuredVideoAdmin)}
              </Text>
            </Link>
          )}
        </Menu.Item>
      )}
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/logout"
            onClick={() => onMobileNavToggle && onMobileNavToggle(false)}
            className={`${
              active ? 'bg-nad-light-blue text-nad-blue' : 'text-nad-blue'
            } border-t    py-5 font-sans group flex w-full uppercase justify-left tracking-wider ${
              isMobileMenu ? mainSpacingClasses : 'px-10 py-5'
            } text-xs font-bold self-left`}
            data-testid="main-nav-logout"
          >
            <Text size={`${isMobileMenu ? 'S' : 'XS'}`} color="nad-blue">
              {intl.formatMessage(messages.logout)}
            </Text>
          </Link>
        )}
      </Menu.Item>
    </>
  );
};

export default UserMainNavMenuItems;
