import { Props, fillColors } from '.';

export const IconArrowBottom = ({
  width = 15,
  height = 15,
  color = 'nad-alps-night',
  className = '',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_1011_1706)">
        <path d="M9.61499 10.125L14.865 4.87499L12.75 2.75999L7.49999 8.00999L2.24999 2.75999L0.134991 4.87499L5.38499 10.125L7.49999 12.24L9.61499 10.125Z" />
      </g>
      <defs>
        <clipPath id="clip0_1011_1706">
          <rect
            width={width}
            height={height}
            fill="none"
            transform="translate(15 15) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
