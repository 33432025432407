import { mutate } from 'swr';
import { ApiHook, useCreateSWREndpoint } from './use-swr-endpoint';
import { useCurrentUser } from './use-user';
import { Notification } from '@models/notification';

export interface NotificationsParams {}

export const mutateNotifications = () => {
  mutate('/api/v3/notifications');
};

export const useNotifications = (): ApiHook<Notification[]> => {
  const url = `/api/v3/notifications`;
  const { data: user } = useCurrentUser();
  const shouldFetch = !!user;
  return useCreateSWREndpoint<Notification[]>({ url, shouldFetch });
};
