import { fillColors, Props } from '.';

export const IconSort = ({
  width = 20,
  height = 20,
  className = '',
  color = 'nad-blue',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={`${fillColors[color]} ${className}`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54968 5.92721L5.23975 4.64515L9.80128 0L14.3628 4.64515L13.0529 5.92721L10.7303 3.60463V9.11178L10.7303 9.24384L10.7303 14.5788L13.0529 12.3455L14.3628 13.5783L9.80128 18.0001L5.23975 13.5783L6.54968 12.3455L8.87225 14.5788V9.24384L8.87225 9.11178V3.60463L6.54968 5.92721Z"
      />
    </svg>
  );
};
