import { fillColors, Props } from '.';

export const IconChevronRight = ({
  width = 12,
  height = 16,
  className = '',
  color = 'nad-blue',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
    >
      <mask id="path-1-inside-1_4655_13574" fill="white">
        <path d="M8.92741 6.19537L3.20568 0.473633L0.900634 2.77868L6.62237 8.50041L0.900632 14.2221L3.20567 16.5272L8.92741 10.8055L11.2325 8.50041L8.92741 6.19537Z" />
      </mask>
      <path d="M8.92741 6.19537L3.20568 0.473633L0.900634 2.77868L6.62237 8.50041L0.900632 14.2221L3.20567 16.5272L8.92741 10.8055L11.2325 8.50041L8.92741 6.19537Z" />
    </svg>
  );
};
