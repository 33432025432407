import LinkN, { LinkProps } from 'next/link';
import { forwardRef, ReactNode } from 'react';

type LinkVariant = 'basic' | 'uppercase' | 'uppercaseBlue' | 'underline';

interface Props extends LinkProps {
  children: ReactNode;
  className?: string;
  variant?: LinkVariant;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ children, variant, className = '', ...props }, ref) => {
    const classMap: Record<LinkVariant, string> = {
      basic: linkBasicUnderline,
      uppercase: linkUppercase,
      uppercaseBlue: linkUppercaseBlue,
      underline: linkUnderline,
    };
    const classes = variant ? classMap[variant] : '';
    return (
      <LinkN ref={ref} className={`${className} ${classes}`} {...props}>
        {children}
      </LinkN>
    );
  }
);

Link.displayName = 'Link';
const baseClasses = ``;

export const linkBasicUnderline = `${baseClasses}
underline text-nad-ming
hover:text-nad-blue
`;

export const linkUppercase = `${baseClasses}
uppercase font-sans tracking-wider font-bold
`;

export const linkUppercaseBlue = `${baseClasses}
text-nad-blue hover:text-nad-ming
uppercase font-sans tracking-wider font-bold
`;

export const linkUnderline = `${baseClasses}
underline
`;
