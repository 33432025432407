import { fillColors, Props } from '.';

export const IconFailed = ({
  width = 16,
  height = 16,
  color = 'nad-scarlett',
  className = 'none',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 10 11`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
    >
      <circle cx="5" cy="5.5" r="5" />
      <path
        d="M6.66683 3.83331L3.3335 7.16665M3.3335 3.83331L6.66683 7.16665L3.3335 3.83331Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
