import { Props, strokeColors, fillColors } from '.';

export const IconChevronRoundedRight = ({
  width = 46,
  height = 46,
  color = 'nad-alps-night',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 46 46`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="45"
        y="45"
        width="44"
        height="44"
        rx="22"
        transform="rotate(-180 45 45)"
        className={strokeColors[color]}
        strokeWidth="2"
      />
      <mask id="path-2-inside-1_1011_1714" className={fillColors[color]}>
        <path d="M26.8013 20.7445L21.2013 15.1445L18.9453 17.4005L24.5453 23.0005L18.9453 28.6005L21.2013 30.8565L26.8013 25.2565L29.0573 23.0005L26.8013 20.7445Z" />
      </mask>
      <path
        d="M26.8013 20.7445L21.2013 15.1445L18.9453 17.4005L24.5453 23.0005L18.9453 28.6005L21.2013 30.8565L26.8013 25.2565L29.0573 23.0005L26.8013 20.7445Z"
        className={fillColors[color]}
      />
    </svg>
  );
};
