import { HtmlHTMLAttributes, ReactNode } from 'react';
import { Heading, HeadingVariant, HeadingVariantAs } from '../heading/heading';
import { TextColors } from '../text/text';

interface Props extends HtmlHTMLAttributes<HTMLHeadingElement> {
  variant: HeadingVariant;
  as?: HeadingVariantAs;
  color?: TextColors;
  children: ReactNode;
}

export const HeadingUppercase = ({
  children,
  className = '',
  ...props
}: Props) => {
  return (
    <Heading
      font="sans"
      className={`uppercase tracking-wider font-bold ${className}`}
      {...props}
    >
      {children}
    </Heading>
  );
};
