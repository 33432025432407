import Image from 'next/image';

interface Props {
  color?: 'blue' | 'white' | 'red';
  width?: number;
}

export const LoadingSpinner = ({ color = 'blue', width = 80 }: Props) => {
  const src = `/noprecache/img/loading-${color}.gif`;
  return (
    <Image
      width={width}
      height={width}
      src={src}
      alt=""
      data-testid="loading-spinner"
    />
  );
};
